import { createAction, props } from "@ngrx/store";
import { VideoFeedback } from "src/app/models/video-feedback/video-feedback";

export const load = createAction("[Video Feedbacks] load");

export const loaded = createAction(
  "[Video Feedbacks] loaded",
  props<{
    videoFeedbacks: VideoFeedback[];
  }>(),
);

export const filter = createAction(
  "[Video Feedbacks] filter",
  props<{
    showFinished: boolean;
    filter: string;
  }>(),
);

export const filtered = createAction(
  "[Video Feedbacks] filtered",
  props<{
    filteredVideoFeedbacks: VideoFeedback[];
  }>(),
);
