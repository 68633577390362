import { createAction, props } from "@ngrx/store";
import { CreatePotentialUser } from "../../models/user/create-potential-user";
import { StoredPotentialUser } from "../../models/user/stored-potential-user";
import { FinishGoogleSignUp } from "src/app/models/user/finish-google-sign-up";

export const registerAction = createAction(
  "[Registration] Register",
  props<CreatePotentialUser>(),
);

export const finishGoogleSignUp = createAction(
  "[Registration] finish google Sign up",
  props<{ signUpData: FinishGoogleSignUp }>(),
);

export const registrationFailedAction = createAction("[Registration] Failed");

export const registrationSuccessAction = createAction(
  "[Registration] Success",
  props<StoredPotentialUser>(),
);

export const verifyEmailAction = createAction(
  "[Registration] Verify Email",
  props<{ code: string; password: string }>(),
);

export const verificationFailedAction = createAction(
  "[Registration] Verification Failed",
);
export const verificationSuccessAction = createAction(
  "[Registration] Verification Success",
  props<{ email: string; password: string }>(),
);
