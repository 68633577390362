import { createAction, props } from "@ngrx/store";
import { Marker } from "src/app/models/marker/marker";
import { EditGuard } from "src/app/models/player/edit-guard";

export const addMarker = createAction(
  "[Marker] Add Marker",
  props<{ marker: Marker }>()
);

export const addedMarker = createAction(
  "[Marker] Added Marker",
  props<{ marker: Marker }>()
);

export const markerInEditModeChange = createAction(
  "[Marker] Marker In Edit Mode Change",
  props<{ marker?: Marker; editGuard?: EditGuard }>()
);

export const deleteMarker = createAction(
  "[Marker] Delete Marker",
  props<{ marker: Marker }>()
);

export const deletedMarker = createAction(
  "[Marker] Deleted Marker",
  props<{ marker: Marker }>()
);

export const markersLoaded = createAction(
  "[Marker] Markers loaded",
  props<{ markers: Marker[] }>()
);

export const moveMarker = createAction(
  "[Marker] Move marker",
  props<{ direction: "<" | ">" }>()
);
