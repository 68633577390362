import { createAction, props } from "@ngrx/store";
import { PushNotificationSubscription } from "src/app/models/notification/push-subscription";
import { ServerPushNotificationSettings } from "src/app/models/notification/server-push-notification-settings";
import { UserNotificationSettings } from "src/app/models/notification/user-notification-settings";

export const loadServerSettings = createAction(
  "[Notification Settings] load server settings",
);

export const loadedServerSettings = createAction(
  "[Notification Settings] loaded server settings",
  props<{
    settings: ServerPushNotificationSettings;
  }>(),
);

export const loadUserSettings = createAction(
  "[Notification Settings] load user settings",
);

export const loadedUserSettings = createAction(
  "[Notification Settings] loaded user settings",
  props<{
    settings?: UserNotificationSettings;
  }>(),
);

export const saveUserSettings = createAction(
  "[Notification Settings] save user settings",
  props<{
    settings: {
      enabled: boolean;
      email: boolean;
      push: boolean;
    };
  }>(),
);

export const savedUserSettings = createAction(
  "[Notification Settings] saved user settings",
  props<{
    settings: UserNotificationSettings;
  }>(),
);

export const tryFixPushRegistration = createAction(
  "[Notification Settings] try fix push registration",
);

// triggered by angular's swPush
// updatedPushSubscription is triggered by Angular's SwPush service and is used for the state changes
export const updatedPushSubscription = createAction(
  "[Notification Settings] updated push subscription",
  props<{
    subscription?: PushSubscription;
  }>(),
);

// used to request push notifications from angular's SWPush
export const tryRegisterPushSubscription = createAction(
  "[Notification Settings] try register push subscription",
  props<{
    vapidPublicKey: string;
  }>(),
);

// when it failes we get the info instantly
export const registerPushSubscriptionFailed = createAction(
  "[Notification Settings] register push subscription failed",
  props<{
    error?: any;
  }>(),
);

// does not contain the push subscription as we receive the subscription from angular's SwPush Service and updatedPushSubscription(see above) respectively
export const registerPushSubscriptionSuccess = createAction(
  "[Notification Settings] register push subscription success",
);

// used to unregister push notifications from angular's SWPush
export const unregisterPushSubscription = createAction(
  "[Notification Settings] unregister push subscription",
);

// backend: we can only create once: updates happen only in the backend
export const createPushSubscription = createAction(
  "[Notification Settings] create push subscription",
  props<{
    subscription: PushSubscription;
  }>(),
);

// backend
export const createdPushSubscription = createAction(
  "[Notification Settings] created push subscription",
  props<{
    subscription: PushNotificationSubscription;
  }>(),
);

// backend
export const createPushSubscriptionFailed = createAction(
  "[Notification Settings] create push subscription failed",
  props<{ error?: any }>(),
);

// backend
export const deletePushSubscription = createAction(
  "[Notification Settings] delete push subscription",
  props<{
    subscription: PushNotificationSubscription;
  }>(),
);

// backend
export const deletedPushSubscription = createAction(
  "[Notification Settings] deleted push subscription",
);
