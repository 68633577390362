import { createAction, props } from "@ngrx/store";
import { NotificationTrigger } from "src/app/models/notification/notification-trigger";

export const loadNotifications = createAction("[Notification] load");

export const loadedNotifications = createAction(
  "[Notification] loaded",
  props<{
    notifications: NotificationTrigger[];
  }>(),
);

export const markAsRead = createAction(
  "[Notification] mark as read",
  props<{
    notification: NotificationTrigger;
  }>(),
);

export const markAsReadByName = createAction(
  "[Notification] mark as read by name",
  props<{
    name: string;
  }>(),
);

export const markedAsRead = createAction(
  "[Notification] marked as read",
  props<{ notification: NotificationTrigger }>(),
);

export const markAllAsRead = createAction("[Notification] mark all as read");

export const markedAllAsRead = createAction(
  "[Notification] marked all as read",
);
