import { createAction, props } from "@ngrx/store";
import { Authentication } from "src/app/models/authentication/authentication";
import { StoredUser } from "src/app/models/user/stored-user";
import { StripeCustomer } from "src/app/models/user/stripe-customer";
import { UserData } from "src/app/models/user/user-data";

export const login = createAction(
  "[User] login",
  props<{ auth: Authentication }>(),
);

export const loggedIn = createAction(
  // TODO: #96 we have to re-use this even when we have the sign up / sign in  via google as this is used by other effects
  "[User] logged in",
  props<{ userData: UserData }>(),
);

export const loginFailed = createAction(
  "[User] Login failed",
  props<{ error: any }>(),
);

export const loadUserDetails = createAction("[User] load user details");

export const loadedUserDetails = createAction(
  "[User] Loaded User Details",
  props<{ userDetails: StoredUser }>(),
);

export const triggerLogout = createAction("[User] trigger logout"); // triggers the logout, can be used to clean up stuff (careful: the user won't be authenticated in the backend.)

export const logout = createAction("[User] logout"); // actual logout

export const switchLanguage = createAction(
  "[User] switch language",
  props<{ language: string }>(),
);

export const switchedLanguage = createAction(
  "[User] switched language",
  props<{ language: string }>(),
);

export const updatePassword = createAction(
  "[User] update password",
  props<{ newPassword: string }>(),
);

export const updatedPassword = createAction(
  "[User] updated password",
  props<{ apiSecret: string }>(),
);

export const loadStripeCustomer = createAction("[User] load stripe customer");

export const loadedStripeCustomer = createAction(
  "[User] loaded stripe customer",
  props<{
    customer?: StripeCustomer;
  }>(),
);

export const signInWIthGoogle = createAction(
  "[User] sign in with google",
  props<{
    credential: string;
  }>(),
);

export const signInWithGoogleRequiresSignUp = createAction(
  "[User] sign in with google requires sign up",
  props<{
    credential: string;
  }>(),
);
