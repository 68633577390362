import { createAction, props } from "@ngrx/store";
import { UsedResourcesForUser } from "../../models/user/used-resources-for-user";

export const loadUsedResourcesAction = createAction(
  "[Used Resources for User] Load"
);

export const usedResourcesLoadedSuccessfullyAction = createAction(
  "[Used Resources for User] Loaded Successfully",
  props<UsedResourcesForUser>()
);
