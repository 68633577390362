import { createAction, props } from "@ngrx/store";
import { StoredArtistProfile } from "src/app/models/artist/stored-artist-profile";
import { TeaseSelectionDialogResult } from "src/app/models/artist/teaser-selection-dialog-result";
import { Teaser } from "src/app/models/artist/teaster";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { Preview } from "src/app/models/music-piece/preview";
import { Recording } from "src/app/models/recording/recording";
import { ShowcaseCollectionListing } from "src/app/models/artist/showcase-collection-listing";

export const loadArtistProfileForCurrentUser = createAction(
  "[Artist Profile] load for current user",
  props<{ redirectToArtistProfileAfterLoading?: boolean }>(),
);

export const loadedArtistProfileForCurrentUser = createAction(
  "[Artist Profile] loaded for current user",
  props<{ artistProfile?: StoredArtistProfile; requiresRedirect?: boolean }>(),
);

export const loadArtistProfile = createAction(
  "[Artist Profile] load",
  props<{ name: string }>(),
);

export const loadArtistProfileByCustomUrl = createAction(
  "[Artist Profile] load by custom url",
  props<{ customUrl: string }>(),
);

export const focusMusicPiece = createAction(
  "[Artist Profile] focus music piece",
  props<{ musicPiece?: string }>(),
);

export const loadedArtistProfile = createAction(
  "[Artist Profile] loaded",
  props<{ artistProfile?: StoredArtistProfile }>(),
);

export const loadedListedMusicPieces = createAction(
  "[Artist Profile] loaded listed music pieces",
  props<{ musicPieces: MusicPieceListing[] }>(),
);

export const loadedListingPreviews = createAction(
  "[Artist Profile] loaded listing previews",
  props<{
    previews: Preview[];
  }>(),
);

export const loadedListedCollections = createAction(
  "[Artist Profile] loaded listed collections",
  props<{
    collections: ShowcaseCollectionListing[];
  }>(),
);

export const updateProfile = createAction(
  "[Artist Profile] update",
  props<{
    artistProfile: StoredArtistProfile;
  }>(),
);

export const updatedProfile = createAction(
  "[Artist Profile] updated",
  props<{
    artistProfile: StoredArtistProfile;
  }>(),
);

export const updateProfileFailed = createAction(
  "[Artist Profile] update failed",
  props<{
    error: any;
  }>(),
);

export const deleteProfilePicture = createAction(
  "[Artist Profile] delete profile picture",
  props<{ profile: StoredArtistProfile }>(),
);

export const deleteLogo = createAction(
  "[Artist Profile] delete logo",
  props<{ profile: StoredArtistProfile }>(),
);

export const loadPossibleTeasers = createAction(
  "[Artist Profile] load possible teasers",
  props<{ profile: StoredArtistProfile }>(),
);

export const loadedPossibleTeasers = createAction(
  "[Artist Profile] loaded possible teasers",
  props<{
    teasers: MusicPiece[];
  }>(),
);

export const loadTeaser = createAction(
  "[Artist Profile] load teaser",
  props<{
    artistProfile: StoredArtistProfile;
  }>(),
);

export const loadedTeaser = createAction(
  "[Artist Profile] loaded teaser",
  props<{
    teaser?: Teaser;
  }>(),
);
