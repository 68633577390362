import { createAction, props } from "@ngrx/store";
import { ArtistCertification } from "src/app/models/user/artist-certification";

export const loadArtistCertification = createAction(
  "[Artist Certification] load",
);

export const loadedArtistCertification = createAction(
  "[Artist Certification] loaded",
  props<{ certification: ArtistCertification | undefined }>(),
);

export const createCertification = createAction(
  "[Artist Certification] create Certification",
);

export const createdCertification = createAction(
  "[Artist Certification] created Certification",
  props<{ certification: ArtistCertification }>(),
);

export const requestReview = createAction(
  "[Artist Certification] request Review",
);

export const reviewRequested = createAction(
  "[Artist Certification] review requested",
  props<{
    certification: ArtistCertification;
  }>(),
);
