import { createAction, props } from "@ngrx/store";
import { FaqItem } from "src/app/models/faq/faq-item";

export const load = createAction("[FAQ] load");
export const loaded = createAction(
  "[FAQ] loaded",
  props<{
    items: FaqItem[];
  }>(),
);

export const search = createAction("[FAQ] search", props<{ query: string }>());

export const searched = createAction(
  "[FAQ] searched",
  props<{
    searchResults: FaqItem[];
  }>(),
);
