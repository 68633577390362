import { createAction, props } from "@ngrx/store";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { StoredLinkedCollectionItem } from "src/app/models/collection/stored-linked-collection-item";
import { PracticeConfirmation } from "src/app/models/practice-routine/practice-confirmation";
import { PracticeRoutineDisplayItem } from "src/app/models/practice-routine/practice-routine-display-item";

export type CollectionType = "collection" | "linkedCollection";

export const load = createAction(
  "[Practice Routine] load",
  props<{
    name: string;
    collectionType: CollectionType;
  }>(),
);

export const loaded = createAction(
  "[Practice Routine] loaded",
  props<{
    practiceRoutine: PracticeRoutineDisplayItem;
  }>(),
);

export const loadPracticeConfirmations = createAction(
  "[Practice Routine] load practice confirmations",
  props<{
    name: string;
    collectionType: CollectionType;
  }>(),
);

export const loadedPracticeConfirmations = createAction(
  "[Practice Routine] loaded practice confirmations",
  props<{
    practiceConfirmations: PracticeConfirmation[];
  }>(),
);

export const loadCollectionItems = createAction(
  "[Practice Routine] load collection items",
  props<{
    name: string;
    collectionType: CollectionType;
  }>(),
);

export const loadedCollectionItems = createAction(
  "[Practice Routine] loaded collection items",
  props<{
    collectionItems: StoredCollectionItem[];
    linkedCollectionItems: StoredLinkedCollectionItem[];
  }>(),
);

export const markAsPracticed = createAction(
  "[Practice Routine] mark as practiced",
  props<{
    collectionItem: string; // <- either this
    linkedCollectionItem: string; // <- or this
    rating?: 1 | 2 | 3 | 4 | 5;
    summary?: string;
  }>(),
);

export const markedAsPracticed = createAction(
  "[Practice Routine] marked as practiced",
  props<{ confirmation: PracticeConfirmation }>(),
);

export const loadPlayerCollectionItem = createAction(
  "[Practice Routine] load player collection item",
  props<{
    musicPieceOrLinkedMusicPiece: string;
    collectionOrLinkedCollection: string;
  }>(),
);

export const loadedPlayerCollectionItem = createAction(
  "[Practice Routine] loaded player collection item",
  props<{
    collectionItem?: string;
    collectionType: CollectionType;
  }>(),
);
