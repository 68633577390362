import { createAction, props } from "@ngrx/store";
import { CollectionItemToDisplay } from "src/app/models/collection/collection-item-to-display";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { StoredCollectionListing } from "src/app/models/collection/stored-collection-listing";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";

export const openCollectionForEdit = createAction(
  "[Edit Collection] open collection for edit",
  props<{ name?: string; isNew: boolean }>(),
);

export const loadedCollection = createAction(
  "[Edit Collection] loaded",
  props<{ collection: StoredCollection }>(),
);

export const saveCollection = createAction(
  "[Edit Collection] save",
  props<{
    isNew: boolean;
    title: string;
    name?: string;
    instrument: string;
    temperament: string;
    artist: string;
    description: string;
    acceptShareTerms: boolean;
    isPracticeRoutine: boolean;
  }>(),
);

export const savedCollection = createAction(
  "[Edit Collection] saved",
  props<{ collection: StoredCollection; wasNew: boolean }>(),
);

export const saveCollectionFailed = createAction(
  "[Edit Collection] save failed",
  props<{ error: any }>(),
);

export const loadedCollectionItems = createAction(
  "[Edit Collection] loaded collection items",
  props<{ collectionItems: StoredCollectionItem[] }>(),
);

export const loadCollectionItemsToAdd = createAction(
  "[Edit Collection] load collection items to add",
  props<{
    existingCollectionItems: StoredCollectionItem[];
    acceptedShareTerms: boolean;
  }>(),
);

export const loadedCollectionItemsToAdd = createAction(
  "[Edit Collection] loaded collection items to add",
  props<{ collectionItemsToAdd: MusicPieceToDisplay[] }>(),
);

export const addCollectionItem = createAction(
  "[Edit Collection] add collection item",
  props<{
    collectionItemToAdd: MusicPieceToDisplay;
    collection: StoredCollection;
    order: number;
  }>(),
);

export const addedCollectionItem = createAction(
  "[Edit Collection] added collection item",
  props<{ collectionItem: StoredCollectionItem }>(),
);

export const addCollectionItemFailed = createAction(
  "[Edit Collection] add collection item failed",
  props<{ error: any }>(),
);

export const removeCollectionItem = createAction(
  "[Edit Collection] remove collection item",
  props<{ collectionItemToRemove: StoredCollectionItem }>(),
);

export const removedCollectionItem = createAction(
  "[Edit Collection] removed collection item",
  props<{ collectionItemToRemove: StoredCollectionItem }>(),
);

export const removeCollectionItemFailed = createAction(
  "[Edit Collection] remove collection item failed",
  props<{ error: any }>(),
);

export const editCollectionItems = createAction(
  "[Edit Collection] edit collection items",
  props<{ updateCollectionItems: StoredCollectionItem[] }>(),
);

export const editedCollectionItems = createAction(
  "[Edit Collection] edited collection items",
  props<{ updatedCollectionItems: StoredCollectionItem[] }>(),
);

export const editCollectionItemsFailed = createAction(
  "[Edit Collection] edit collection items failed",
  props<{ error: any }>(),
);

export const filterCollectionItems = createAction(
  "[Edit Collection] filter collection items",
  props<{ filter: string }>(),
);

export const filteredCollectionItems = createAction(
  "[Edit Collection] filtered collection items",
  props<{
    filteredCollectionItems: CollectionItemToDisplay[];
  }>(),
);

export const filterCollectionItemsToAdd = createAction(
  "[Edit Collection] filter collection items to add",
  props<{ filter: string }>(),
);

export const filteredCollectionItemsToAdd = createAction(
  "[Edit Collection] filtered collection items to add",
  props<{
    filteredMusicPiecesToAdd: MusicPieceToDisplay[];
  }>(),
);

export const deleteCollection = createAction(
  "[Edit Collection] delete",
  props<{
    collection: StoredCollection;
  }>(),
);

export const deletedCollection = createAction(
  "[Edit Collection] deleted",
  props<{
    collection: StoredCollection;
  }>(),
);

export const deleteCollectionFailed = createAction(
  "[Edit Collection] delete failed",
  props<{ error?: any }>(),
);

export const share = createAction(
  "[Edit Collection] share",
  props<{
    collection: StoredCollection;
    receiver: string;
  }>(),
);

export const shared = createAction(
  "[Edit Collection] shared",
  props<{
    collection: StoredCollection;
    receiver: StoredLinkedCollection;
  }>(),
);

export const shareFailed = createAction(
  "[Edit Collection] share failed",
  props<{
    error?: any;
  }>(),
);

export const loadLinkedCollections = createAction(
  "[Edit Collection] load linked collections",
  props<{ collection: StoredCollection }>(),
);

export const loadedLinkedCollections = createAction(
  "[Edit Collection] loaded linked collections",
  props<{
    linkedCollections: StoredLinkedCollection[];
  }>(),
);

export const loadedLinkedCollectionsFailed = createAction(
  "[Edit Collection] loaded linked collection failed",
  props<{
    error?: any;
  }>(),
);

export const unshare = createAction(
  "[Edit Collection] unshare",
  props<{
    linkedCollection: StoredLinkedCollection;
  }>(),
);

export const unshared = createAction(
  "[Edit Collection] unshared",
  props<{
    collection: StoredCollection;
    linkedCollection: StoredLinkedCollection;
  }>(),
);

export const unshareFailed = createAction(
  "[Edit Collection] unshare failed",
  props<{
    error?: any;
  }>(),
);

export const saveCollectionListing = createAction(
  "[Edit Collection] save listing",
  props<{
    collection: string;
    name?: string;
    price: number;
    shouldList: boolean;
  }>(),
);

export const loadCollectionListing = createAction(
  "[Edit Collection] load listing",
  props<{ collection: string }>(),
);

export const loadedCollectionListing = createAction(
  "[Edit Collection] loaded listing",
  props<{ listing?: StoredCollectionListing }>(),
);

export const createdCollectionListing = createAction(
  "[Edit Collection] created listing",
  props<{ listing: StoredCollectionListing }>(),
);

export const updatedCollectionListing = createAction(
  "[Edit Collection] updated listing",
  props<{ listing: StoredCollectionListing }>(),
);

export const deletedCollectionListing = createAction(
  "[Edit Collection] deleted listing",
);

export const saveCollectionListingFailed = createAction(
  "[Edit Collection] save collection listing failed",
  props<{ error?: any }>(),
);
