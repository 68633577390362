import { createAction, props } from "@ngrx/store";
import { License, UserLicense } from "../../models/user/user-license";
import { BillingInterval } from "src/app/models/license/billing-interval";
import { SubscriptionCheckoutSession } from "src/app/models/license/subscription-checkout-session";
import { CustomerPortalSession } from "src/app/models/license/customer-portal-session";
import { CheckoutSession } from "src/app/models/license/checkout-session";

export const loadLicenseAction = createAction("[License] Load");

export const licenseLoadedSuccessfullyAction = createAction(
  "[License] Loaded Successfully",
  props<UserLicense>(),
);

export const loadFallbackAndUpgrade = createAction(
  "[License] load fallback and upgrade",
);

export const loadedFallbackAndUpgrade = createAction(
  "[License] loaded fallback and upgrade",
  props<{
    fallback?: License;
    upgrade?: License;
  }>(),
);

export const purchaseSubscription = createAction(
  "[License] purchase subscription",
  props<{
    license: License;
    billingInterval: BillingInterval;
  }>(),
);

export const subscriptionPurchased = createAction(
  "[License] subscription purchased",
);

export const checkoutSessionCreated = createAction(
  "[License] checkout session created",
  props<{
    session: SubscriptionCheckoutSession;
  }>(),
);

export const checkoutSessionFailed = createAction(
  "[License] checkout session failed",
  props<{
    error?: any;
  }>(),
);

export const openCustomerPortal = createAction(
  "[License] open customer portal",
  props<{ returnUrl: string }>(),
);

export const customerPortalSessionCreated = createAction(
  "[License] customer portal session created",
  props<{
    customerPortal: CustomerPortalSession;
  }>(),
);

export const openCustomerPortalFailed = createAction(
  "[License] open customer portal failed",
  props<{
    error?: any;
  }>(),
);

export const checkCheckoutSessionStatus = createAction(
  "[License] check checkout session status",
  props<{
    stripeCheckoutSessionId: string;
  }>(),
);

export const checkoutSessionStatusReceived = createAction(
  "[License] checkout session status received",
  props<{
    checkoutSession?: CheckoutSession;
  }>(),
);
