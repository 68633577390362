import { createAction, props } from "@ngrx/store";
import { VideoFeedback } from "src/app/models/video-feedback/video-feedback";
import { VideoFeedbackItem } from "src/app/models/video-feedback/video-feedback-item";

export const load = createAction(
  "[Video Feedback Details] load",
  props<{
    videoFeedback: string;
  }>(),
);

export const loaded = createAction(
  "[Video Feedback Details] loaded",
  props<{
    videoFeedback: VideoFeedback;
  }>(),
);

export const loadItems = createAction(
  "[Video Feedback Details] load items",
  props<{
    videoFeedback: string;
  }>(),
);

export const loadedItems = createAction(
  "[Video Feedback Details] loaded items",
  props<{
    videoFeedbackItems: VideoFeedbackItem[];
  }>(),
);

export const createVideoFeedbackItem = createAction(
  "[Video Feedback Details] create item",
  props<{
    videoFeedback: string;
    musicPiece: string | undefined;
    message: string | undefined;
  }>(),
);

export const createdVideoFeedbackItem = createAction(
  "[Video Feedback Details] created item",
  props<{
    videoFeedbackItem: VideoFeedbackItem;
  }>(),
);

export const createVideoFeedbackItemFailed = createAction(
  "[Video Feedback Details] create item failed",
  props<{
    error?: any;
  }>(),
);

export const markAsRead = createAction(
  "[Video Feedback Details] mark as read",
  props<{
    videoFeedback: string;
  }>(),
);

export const markedAsRead = createAction(
  "[Video Feedback Details] marked as read",
  props<{
    videoFeedbackItems: VideoFeedbackItem[];
  }>(),
);

export const markAsFinished = createAction(
  "[Video Feedback Details] mark as finished",
  props<{ videoFeedback: string }>(),
);

export const markedAsFinished = createAction(
  "[Video Feedback Details] marked as finished",
  props<{ videoFeedback: VideoFeedback }>(),
);

export const updateTheme = createAction(
  "[Video Feedback Details] update  theme",
  props<{ videoFeedback: string; theme: string }>(),
);

export const updatedTheme = createAction(
  "[Video Feedback Details] updated theme",
  props<{ videoFeedback: VideoFeedback }>(),
);
