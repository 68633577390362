import { Observable } from '../Observable';
import { EMPTY } from './empty';
export function range(start, count, scheduler) {
  if (count == null) {
    count = start;
    start = 0;
  }
  if (count <= 0) {
    return EMPTY;
  }
  const end = count + start;
  return new Observable(scheduler ? subscriber => {
    let n = start;
    return scheduler.schedule(function () {
      if (n < end) {
        subscriber.next(n++);
        this.schedule();
      } else {
        subscriber.complete();
      }
    });
  } : subscriber => {
    let n = start;
    while (n < end && !subscriber.closed) {
      subscriber.next(n++);
    }
    subscriber.complete();
  });
}
