import { createAction, props } from "@ngrx/store";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { SharedMusicPieceReceiver } from "src/app/models/music-piece/shared-music-piece-receiver";
import { MusicScore } from "src/app/models/music-score/music-score";
import { Recording } from "src/app/models/recording/recording";
import { LinkedMusicPieceWithMusicPiece } from "src/app/services/linked-music-piece/linked-music-piece.service";

export const openMusicPieceForEdit = createAction(
  "[Edit Music Piece] open music piece for edit",
  props<{
    isNew: boolean;
    isLinkedMusicPiece: boolean;
    name: string;
  }>(),
);

export const loaded = createAction(
  "[Edit Music Piece] loaded",
  props<{
    musicPiece?: MusicPiece;
    linkedMusicPieceWithMusicPiece?: LinkedMusicPieceWithMusicPiece;
  }>(),
);

export const loadedRecording = createAction(
  "[Edit Music Piece] loaded recording",
  props<{
    recording?: Recording;
  }>(),
);

export const loadedMusicScore = createAction(
  "[Edit Music Piece] loaded music score",
  props<{
    musicScore?: MusicScore;
  }>(),
);

export const save = createAction(
  "[Edit Music Piece] save",
  props<{
    nameOfPiece: string;
    name?: string;
    acceptShareTerms: boolean;
    instrument: string;
    temperament: string;
    artist: string;
    description: string;
    collection: string;
  }>(),
);

export const saved = createAction(
  "[Edit Music Piece] saved",
  props<{ musicPiece: MusicPiece }>(),
);

export const deleteMusicPiece = createAction(
  "[Edit Music Piece] delete",
  props<{
    musicPiece?: MusicPiece;
    linkedMusicPiece?: LinkedMusicPiece;
  }>(),
);

export const deletedMusicPiece = createAction(
  "[Edit Music Piece] deleted",
  props<{
    musicPiece?: MusicPiece;
    linkedMusicPiece?: LinkedMusicPiece;
  }>(),
);

export const deleteMusicScore = createAction(
  "[Edit Music Piece] delete music score",
  props<{ musicScore: MusicScore }>(),
);

export const deletedMusicScore = createAction(
  "[Edit Music Piece] deleted music score",
  props<{ musicScore: MusicScore }>(),
);

export const deleteRecording = createAction(
  "[Edit Music Piece] delete recording",
  props<{ recording: Recording }>(),
);

export const deletedRecording = createAction(
  "[Edit Music Piece] deleted recording",
  props<{ recording: Recording }>(),
);

export const recordingUploadFinished = createAction(
  "[Edit Music Piece] recording upload finished",
);

export const updateRecordingUsesMultipleTracks = createAction(
  "[Edit Music Piece] update recording used multiple tracks",
  props<{
    recording: string;
    enableMultipleTracks: boolean;
  }>(),
);

export const thumbnailUploadFinished = createAction(
  "[Edit Music Piece] thumbnail upload finished",
  props<{ musicPiece: MusicPiece }>(),
);

export const deletedThumbnail = createAction(
  "[Edit Music Piece] thumbnail deleted",
  props<{ musicPiece: MusicPiece }>(),
);

export const checkIfRenderIsFinished = createAction(
  "[Edit Music Piece] check if render is finished",
);

export const recordingRenderingFinished = createAction(
  "[Edit Music Piece] recording rendering finished",
  props<{ recording: Recording }>(),
);

export const musicScoreUploadFinished = createAction(
  "[Edit Music Piece] music score upload finished",
);

export const createdRecording = createAction(
  "[Edit Music Piece] created recording",
  props<{ recording: Recording }>(), // because the upload element creates the recording: it does not suit into ngrx well.
);

export const createdMusicScore = createAction(
  "[Edit Music Piece] created music score",
  props<{ musicScore: MusicScore }>(), // because the upload element creates the music score: it does not suit into ngrx well.
);

export const shareMusicPiece = createAction(
  "[Edit Music Piece] share",
  props<{ musicPiece: MusicPiece; receiver: string }>(),
);

export const sharedMusicPiece = createAction(
  "[Edit Music Piece] shared",
  props<{ musicPiece: MusicPiece; receiver: string }>(),
);

export const loadedSharedMusicPieceReceivers = createAction(
  "[Edit Music Piece] loaded shared music piece receivers",
  props<{ receivers: SharedMusicPieceReceiver[] }>(),
);

export const unshareMusicPiece = createAction(
  "[Edit Music Piece] unshare",
  props<{ musicPiece: MusicPiece; linkedMusicPiece: string }>(),
);

export const unsharedMusicPiece = createAction(
  "[Edit Music Piece] unshared",
  props<{ musicPiece: MusicPiece; linkedMusicPiece: string }>(),
);

export const musicPieceListingLoaded = createAction(
  "[Edit Music Piece] listing loaded",
  props<{ listing?: MusicPieceListing }>(),
);

export const saveMusicPieceListing = createAction(
  "[Edit Music Page] save listing",
  props<{
    musicPiece: string;
    name?: string;
    price: number;
    isListing: boolean;
  }>(),
);

export const createdMusicPieceListing = createAction(
  "[Edit Music Piece] created listing",
  props<{ listing: MusicPieceListing }>(),
);

export const updatedMusicPieceListing = createAction(
  "[Edit Music Piece] updated listing",
  props<{ listing: MusicPieceListing }>(),
);

export const deletedMusicPieceListing = createAction(
  "[Edit Music Piece] deleted listing",
);
