import { createAction, props } from "@ngrx/store";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";
import { Preview } from "src/app/models/music-piece/preview";
import { LinkedMusicPieceWithMusicPiece } from "src/app/services/linked-music-piece/linked-music-piece.service";

export const loadMusicPieces = createAction("[Music Piece] load music pieces");
export const loadedMusicPieces = createAction(
  "[Music Piece] loaded music pieces",
  props<{
    musicPieces: MusicPiece[];
  }>(),
);

export const loadedLinkedMusicPieces = createAction(
  "[Music Piece] loaded linked music pieces",
  props<{
    linkedMusicPieces: LinkedMusicPieceWithMusicPiece[];
  }>(),
);

export const createCopyFromLinkedMusicPiece = createAction(
  "[Music Piece] Create Copy From Linked Music Piece",
  props<{
    linkedMusicPiece: LinkedMusicPiece;
  }>(),
);

export const createdCopyFromLinkedMusicPiece = createAction(
  "[Music Piece] Created Copy from Linked Music Piece",
);

export const loadedMusicPiecePreviews = createAction(
  "[Music Piece] loaded music piece previews",
  props<{
    previews: Preview[];
  }>(),
);

export const loadedLinkedMusicPiecePreviews = createAction(
  "[Music Piece] loaded linked music piece previews",
  props<{
    previews: Preview[];
  }>(),
);

export const filterMusicPieces = createAction(
  "[Music Piece] filter music pieces",
  props<{
    filter: string;
    includeLinkedMusicPieces: boolean;
  }>(),
);

export const filteredMusicPieces = createAction(
  "[Music Piece] filtered music pieces",
  props<{
    filteredMusicPieces: MusicPieceToDisplay[];
  }>(),
);
