import { createAction, props } from "@ngrx/store";
import { DisplayedPage } from "src/app/components/music-score/music-score/music-score.component";
import { Brush } from "src/app/components/player/recording-part-painter/recording-part-painter.component";
import { MusicScoreOverlay } from "src/app/models/music-score/music-score-overlay";
import { LinkedMusicPiece } from "../../models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "../../models/music-piece/music-piece";
import { MusicScore } from "../../models/music-score/music-score";
import { PageBreak } from "../../models/page-break/page-break";
import { Recording } from "../../models/recording/recording";

export const openPlayerAction = createAction(
  "[Player] Open",
  props<{
    name: string;
    isLinkedMusicPiece: boolean;
  }>(),
);

export const musicPieceLoaded = createAction(
  "[Player] MusicPiece Loaded",
  props<{ musicPiece: MusicPiece; linkedMusicPiece?: LinkedMusicPiece }>(),
);

export const recordingLoaded = createAction(
  "[Player] Recording Loaded (with Download Link)",
  props<{ recording: Recording }>(),
);

export const recordingLoadingFailed = createAction(
  "[Player] Recording Loading Failed",
);

export const pageBreaksLoaded = createAction(
  "[Player] Page Breaks loaded",
  props<{ pageBreaks: PageBreak[] }>(),
);

export const musicScoreLoaded = createAction(
  "[Player] Music Score Loaded",
  props<{ musicScore: MusicScore }>(),
);

export const deletePageBreak = createAction(
  "[Player] Delete Page Break",
  props<{ pageBreak: PageBreak }>(),
);

export const addPageBreak = createAction(
  "[Player] Add Page Break",
  props<{ pageBreak: PageBreak }>(),
);

export const pageBreakAdded = createAction(
  "[Player] Page Break Added",
  props<{ pageBreak: PageBreak }>(),
);

export const pageBreakDeleted = createAction(
  "[Player] Page Break Deleted",
  props<{ pageBreak: PageBreak }>(),
);

export const setAutoPageFlip = createAction(
  "[Player] Toggle Auto Page Flip",
  props<{ hasAutoPageFlip: boolean }>(),
);

export const showOverlay = createAction(
  "[Player] Show Overlay",
  props<{ showOverlay: boolean }>(),
);

export const displayedPageChanged = createAction(
  "[Player] Displayed Page Changed",
  props<{ displayedPage?: DisplayedPage; previousPageNumber: number }>(),
);

export const overlayLoaded = createAction(
  "[Player] Overlay Loaded",
  props<{ overlay?: MusicScoreOverlay }>(),
);

export const brushChanged = createAction(
  "[Player] Brush Changed",
  props<{ brush?: Brush }>(),
);

export const isInDrawModeChanged = createAction(
  "[Player] Is In Draw Mode Changed",
  props<{ isInDrawMode: boolean }>(),
);

export const saveImage = createAction(
  "[Player] Save Image",
  props<{ imageData: string }>(),
);

export const savedImage = createAction(
  "[Player] Saved Image",
  props<{ image: MusicScoreOverlay }>(),
);

export const changePitch = createAction(
  "[Player] change pitch",
  props<{ pitch: number }>(),
);

export const muteTracks = createAction(
  "[Player] mute tracks",
  props<{ mutedTracks: number[] }>(),
);
