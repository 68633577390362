import { createAction, props } from "@ngrx/store";
import { Rotation } from "src/app/models/rotation/rotation";

export const loadedRotation = createAction(
  "[Rotation] Rotation Loaded",
  props<{ rotation?: Rotation }>()
);

export const storeRotation = createAction(
  "[Rotation] Store",
  props<{ rotation: Rotation }>()
);

export const storedRotation = createAction(
  "[Rotation] Stored",
  props<{ rotation: Rotation }>()
);
