import { createAction, props } from "@ngrx/store";
import { CollectionPurchase } from "src/app/models/collection/collection-purchase";
import { MusicPiecePurchase } from "src/app/models/music-piece/music-piece-purchase";
import { ItemToPurchase } from "src/app/models/purchase/item-to-purchase";
import { PurchaseSettings } from "src/app/models/purchase/purchase-settings";
import {
  PreparedPurchaseItem,
  PurchasedItem,
} from "src/app/models/purchase/purchased-item";
import { VideoFeedbackPurchase } from "src/app/models/video-feedback/video-feedback-purchase";

export const loadPurchasedMusicPieces = createAction(
  "[Purchase] load purchased music pieces",
  props<{ includeUnfinished: boolean }>(),
);

export const loadedPurchasedMusicPieces = createAction(
  "[Purchase] loaded purchased music pieces",
  props<{
    purchasedMusicPieces: MusicPiecePurchase[];
  }>(),
);

export const loadPurchasedCollections = createAction(
  "[Purchase] load purchased collections",
  props<{ includeUnfinished: boolean }>(),
);

export const loadedPurchasedCollections = createAction(
  "[Purchase] loaded purchased collections",
  props<{
    purchasedCollections: CollectionPurchase[];
  }>(),
);

export const loadPurchasedVideoFeedbacks = createAction(
  "[Purchase] load purchased video feedbacks",
  props<{
    includeUnfinished: boolean;
  }>(),
);

export const loadedPurchasedVideoFeedbacks = createAction(
  "[Purchase] loaded purchased video feedbacks",
  props<{
    videoFeedbackPurchases: VideoFeedbackPurchase[];
  }>(),
);

export const loadPurchaseSettings = createAction("[Purchase] load settings");

export const loadedPurchaseSettings = createAction(
  "[Purchase] loaded settings",
  props<{
    settings: PurchaseSettings;
  }>(),
);

export const purchase = createAction(
  "[Purchase] purchase",
  props<{
    item: ItemToPurchase;
  }>(),
);

export const purchasePrepared = createAction(
  "[Purchase] purchase prepared",
  props<{ item: PreparedPurchaseItem }>(),
);

export const purchaseSucceeded = createAction(
  "[Purchase] succeeded",
  props<{
    purchase: PurchasedItem;
  }>(),
);

export const purchaseFailed = createAction(
  "[Purchase] failed",
  props<{
    purchase: PreparedPurchaseItem;
    error: any;
  }>(),
);

export const loadPurchaseDetails = createAction(
  "[Purchase] load details",
  props<{
    purchaseType: "music-piece" | "collection" | "video-feedback";
    clientSecret: string;
  }>(),
);

export const loadedPurchaseDetails = createAction(
  "[Purchase] loaded details",
  props<{
    purchase?: PurchasedItem;
  }>(),
);

export const downloadReceipt = createAction(
  "[Purchase] download receipt",
  props<{
    purchase: PurchasedItem;
  }>(),
);
