import { createAction, props } from "@ngrx/store";
import { RecordingPartGroup } from "src/app/models/recording-part-group/recording-part-group";
import { RecordingPart } from "src/app/models/recording-part/recording-part";

export const loadedRecordingPartGroups = createAction(
  "[Recording Part Group] loaded Groups",
  props<{ recordingPartGroups: RecordingPartGroup[] }>()
);

export const selectRecordingPartGroup = createAction(
  "[Recording Part Group] Select recording Part group",
  props<{ recordingPartGroup?: RecordingPartGroup }>()
);

export const loadedRecordingParts = createAction(
  "[Recording Part Group] Recording Parts Loaded",
  props<{ recordingParts: RecordingPart[] }>()
);

export const addRecordingPartGroup = createAction(
  "[Recording Part Group] Add Recording Part Group",
  props<{ recordingPartGroup: RecordingPartGroup; totalMs: number }>()
);

export const addedRecordingPartGroup = createAction(
  "[Recording Part Group] Added Recording Part Group",
  props<{ recordingPartGroup: RecordingPartGroup }>()
);

export const addRecordingPart = createAction(
  "[Recording Part Group] Add Recording Part",
  props<{ recordingPart: RecordingPart }>()
);

export const addedRecordingPart = createAction(
  "[Recording Part Group] Added Recording Part",
  props<{ recordingPart: RecordingPart }>()
);

export const deleteRecordingPartGroup = createAction(
  "[Recording Part Group] Delete Recording Part Group",
  props<{ recordingPartGroup: RecordingPartGroup }>()
);

export const deletedRecordingPartGroup = createAction(
  "[Recording Part Group] Deleted Recording Part Group",
  props<{ recordingPartGroup: RecordingPartGroup }>()
);

export const deleteRecordingPart = createAction(
  "[Recording Part Group] Delete Recording Part",
  props<{ recordingPart: RecordingPart }>()
);

export const updateRecordingPart = createAction(
  "[Recording Part Group] Update Recording Part",
  props<{ recordingPart: RecordingPart }>()
);

export const deletedRecordingPart = createAction(
  "[Recording Part Group] Deleted Recording Part",
  props<{ recordingPart: RecordingPart }>()
);
