import { createAction, props } from "@ngrx/store";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { StoredLinkedCollectionItem } from "src/app/models/collection/stored-linked-collection-item";

export const loadCollections = createAction("[Collection] load");

export const loadedCollections = createAction(
  "[Collection] loaded",
  props<{ collections: StoredCollection[] }>()
);

export const loadLinkedCollections = createAction(
  "[Collection] load linked collections"
);

export const loadedLinkedCollections = createAction(
  "[Collection] loaded linked collections",
  props<{ linkedCollections: StoredLinkedCollection[] }>()
);

export const loadedCollectionsForLinkedCollections = createAction(
  "[Collection] loaded collections for linked collections",
  props<{
    linkedCollections: StoredLinkedCollection[];
    collectionsForLinkedCollections: StoredCollection[];
  }>()
);

export const filter = createAction(
  "[Collection] filter",
  props<{
    filter: string;
  }>()
);

export const filtered = createAction(
  "[Collection] filtered",
  props<{
    filteredCollections: StoredCollection[];
    filteredLinkedCollections: StoredLinkedCollection[];
    filteredCollectionsForLinkedCollections: StoredCollection[];
  }>()
);

export const loadCollectionItems = createAction(
  "[Collection] load collection items"
);

export const loadedCollectionItems = createAction(
  "[Collection] loaded collection items",
  props<{
    collectionItems: StoredCollectionItem[];
  }>()
);

export const loadLinkedCollectionItems = createAction(
  "[Collection] load linked collection items"
);

export const loadedLinkedCollectionItems = createAction(
  "[Collection] loaded linked collection items",
  props<{
    linkedCollectionItems: StoredLinkedCollectionItem[];
  }>()
);
