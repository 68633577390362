import { createAction, props } from "@ngrx/store";

export const startPracticeTimeAction = createAction(
  "[Used Resources] Start practice time"
);

export const elapsedPracticeTimeAction = createAction(
  "[Used Resources] Elapsed practice time",
  props<{ practiceTime: number; nextStartTime: number }>()
);

export const loggedPracticedTimeSuccessfullyAction = createAction(
  "[Used Resources] Logged Practice Time Successfully",
  props<{ nextStartTime: number }>()
);

export const stopPracticeTimeAction = createAction(
  "[Used Resources] Stop practice time"
);

export const elapsedPlaybackTimeAction = createAction(
  "[Used Resources] Elapsed playback time",
  props<{ playbackTime: number }>()
);

export const loggedElapsedPlaybackTimeSuccessfullyAction = createAction(
  "[Used Resources] Logged Elapsed Playback Time Successfully",
  props<{ loggedPlaybackTime: number }>()
);
