import { createAction, props } from "@ngrx/store";
import { PlaylistItem } from "src/app/models/player/playlist";

export type PlaylistType =
  | "collection"
  | "linkedCollection"
  | "practiceCollection"
  | "practiceLinkedCollection";

export const loadPlaylist = createAction(
  "[Playlist] load",
  props<{
    playlist: string; // see: type
    playlistType: PlaylistType;
  }>(),
);

export const loadedPlaylist = createAction(
  "[Playlist] loaded",
  props<{
    playlistItems: PlaylistItem[];
    playlist: string;
    playlistType: PlaylistType;
  }>(),
);
