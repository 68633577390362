import { createAction, props } from "@ngrx/store";
import { PurchasedItem } from "src/app/models/purchase/purchased-item";
import { CreditPayout } from "src/app/models/sales/credit-payout";
import { CreditStatement } from "src/app/models/sales/credit-statement";

export const loadSales = createAction("[Sales] load Sales");

export const loadedSales = createAction(
  "[Sales] loaded sales",
  props<{
    sales: PurchasedItem[];
  }>(),
);

export const loadCreditStatements = createAction(
  "[Sales] load credit statement",
);

export const loadedCreditStatements = createAction(
  "[Sales] loaded credit statements",
  props<{
    creditStatements: CreditStatement[];
  }>(),
);

export const loadedCreditPayouts = createAction(
  "[Sales] loaded credit payouts",
  props<{
    creditPayouts: CreditPayout[];
  }>(),
);

export const downloadCreditStatement = createAction(
  "[Sales] download credit statement",
  props<{ creditStatement: CreditStatement }>(),
);
