import { createAction, props } from "@ngrx/store";
import { ExploreArtistProfile } from "src/app/models/explore/explore-artist-profile";
import { Preview } from "src/app/models/music-piece/preview";
import { ExploreMusicPiece } from "src/app/models/explore/explore-music-piece";
import { ExploreCollection } from "src/app/models/explore/explore-collection";

export const load = createAction("[Explore] load");

export const loaded = createAction(
  "[Explore] loaded",
  props<{
    items: ExploreArtistProfile[];
  }>(),
);

export const search = createAction(
  "[Explore] search",
  props<{ query: string }>(),
);

export const searchedPartners = createAction(
  "[Explore] searched partners",
  props<{
    searchResults: ExploreArtistProfile[];
  }>(),
);

export const searchedListedMusicPieces = createAction(
  "[Explore] searched listed music pieces",
  props<{
    searchResults: ExploreMusicPiece[];
  }>(),
);

export const loadedSearchedListedMusicPiecePreviews = createAction(
  "[Explore] loaded search listed music piece previews",
  props<{
    previews: Preview[];
  }>(),
);

export const searchedListedCollections = createAction(
  "[Explore] searched listed collections",
  props<{
    searchResults: ExploreCollection[];
  }>(),
);
