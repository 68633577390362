import { createAction, props } from "@ngrx/store";
import { Metronome } from "src/app/models/metronome/metronome";

export const loadedMetronomes = createAction(
  "[Metronome] Loaded Metronomes",
  props<{
    metronomes: Metronome[];
  }>()
);

export const selectedMetronome = createAction(
  "[Metronome] Selected Metronome",
  props<{ metronome?: Metronome }>()
);

export const deleteMetronome = createAction(
  "[Metronome] Delete Metronome",
  props<{ metronome: Metronome }>()
);

export const deletedMetronome = createAction(
  "[Metronome] Deleted Metronome",
  props<{ metronome: Metronome }>()
);

export const updateMetronome = createAction(
  "[Metronome] Update Metronome",
  props<{ metronome: Metronome }>()
);

export const updatedMetronome = createAction(
  "[Metronome] Updated Metronome",
  props<{ metronome: Metronome }>()
);

export const createMetronome = createAction(
  "[Metronome] Create Metronome",
  props<{ nameOfMetronome: string; bpm: number; endValue: number }>()
);

export const createdMetronome = createAction(
  "[Metronome] Created Metronome",
  props<{ metronome: Metronome }>()
);
