import { createAction, props } from "@ngrx/store";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { StoredLinkedCollectionItem } from "src/app/models/collection/stored-linked-collection-item";
import { LinkedCollectionItemToDisplay } from "../../models/collection/collection-item-to-display";

export const open = createAction(
  "[Readonly Collection] open",
  props<{
    linkedCollection: string;
  }>(),
);

export const loadedReadonlyCollection = createAction(
  "[Readonly Collection] loaded",
  props<{
    collection: StoredCollection;
    linkedCollection: StoredLinkedCollection;
  }>(),
);

export const loadCollectionItems = createAction(
  "[Readonly Collection] load collection items",
  props<{
    linkedCollection: StoredLinkedCollection;
  }>(),
);

export const loadedCollectionItems = createAction(
  "[Readonly Collection] loaded collection items",
  props<{
    linkedCollectionItems: StoredLinkedCollectionItem[];
  }>(),
);

export const filterCollectionItems = createAction(
  "[Readonly Collection] filter collection items",
  props<{ filter: string }>(),
);

export const filteredCollectionItems = createAction(
  "[Readonly Collection] filtered collection items",
  props<{ filteredCollectionItems: LinkedCollectionItemToDisplay[] }>(),
);

export const deleteCollection = createAction(
  "[Readonly Collection] delete",
  props<{ collection: StoredCollection }>(),
);

export const deleteLinkedCollection = createAction(
  "[Readonly Collection] delete linked",
  props<{ linkedCollection: StoredLinkedCollection }>(),
);

export const deletedCollection = createAction("[Readonly Collection] deleted");

export const deletionFailed = createAction(
  "[Readonly Collection] deletion failed",
  props<{ error?: any }>(),
);

export const deletedLinkedCollection = createAction(
  "[Readonly Collection] deleted linked",
);

export const deleteLinkedFailed = createAction(
  "[Readonly Collection] delete linked failed",
  props<{ error?: any }>(),
);

export const updateLinkedCollection = createAction(
  "[Readonly Collection] update linked collection",
  props<{
    name: string;
    isPracticeRoutine: boolean;
  }>(),
);

export const updatedLinkedCollection = createAction(
  "[Readonly Collection] updated linked collection",
  props<{
    collection: StoredLinkedCollection;
  }>(),
);

export const updateCollection = createAction(
  "[Readonly Collection] update collection",
  props<{
    name: string;
    title: string;
    instrument: string;
    temperament: string;
    artist: string;
    description: string;
    acceptShareTerms: boolean;
    isPracticeRoutine: boolean;
  }>(),
);

export const updatedCollection = createAction(
  "[Readonly Collection] updated collection",
  props<{
    collection: StoredCollection;
  }>(),
);

export const editLinkedCollectionItems = createAction(
  "[Readonly Collection] edit linked collection items",
  props<{ updateCollectionItems: StoredLinkedCollectionItem[] }>(),
);

export const editedLinkedCollectionItems = createAction(
  "[Readonly Collection] edited linked collection items",
  props<{ updatedCollectionItems: StoredLinkedCollectionItem[] }>(),
);

export const editLinkedCollectionItemsFailed = createAction(
  "[Readonly Collection] edit linked collection items failed",
  props<{ error: any }>(),
);
