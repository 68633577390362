import { createAction, props } from "@ngrx/store";
import { PracticeRoutineDisplayItem } from "src/app/models/practice-routine/practice-routine-display-item";

export const load = createAction("[Practice Routines] load");

export const loaded = createAction(
  "[Practice Routines] loaded",
  props<{
    practiceRoutines: PracticeRoutineDisplayItem[];
  }>(),
);
